import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  DsMultiselectComponent,
  EnumHabilitation,
  EnumProjetEtape,
  EnumRoleStructure,
  EnumScope,
  EnumTypePartenaire,
  EnumTypeStructure,
  FilterDataInterface,
  RadioButtonOption,
  ReferentielDocumentsAap,
  ReferentielDocumentsAapForm,
  ReferentielHttpService,
  ShowToastrService,
  SubscriptionDestroyerComponent,
} from '@shared-ui';
import { Observable } from 'rxjs';
import { codeGed } from '../../../../assets/json/codeGED';
import { CreationDocumentHelperService } from './creation-documents-aap.helper.service';

@Component({
  selector: 'app-creation-documents-aap',
  templateUrl: './creation-documents-aap.component.html',
  styleUrls: ['./creation-documents-aap.component.scss'],
})
export class CreationDocumentsAapComponent extends SubscriptionDestroyerComponent implements OnInit {
  submitClicked = false;
  form!: FormGroup<ReferentielDocumentsAapForm>;

  readonly codeGed = codeGed;

  etapeDataList: FilterDataInterface[] = [];
  structureDataList: FilterDataInterface[] = [];
  typePartenairesDataList: FilterDataInterface[] = [];
  roleStructuresDataList: FilterDataInterface[] = [];
  idDocumentReferentiel: string;
  showForm = false;

  @ViewChild('etapeSelector') etapeSelectorComponent!: DsMultiselectComponent;
  @ViewChild('structureSelector') structureSelectorComponent!: DsMultiselectComponent;
  @ViewChild('structureProjetSelector') structureProjetSelectorComponent!: DsMultiselectComponent;
  @ViewChild('roleStructuresSelector') roleStructuresComponent!: DsMultiselectComponent;

  readonly OPTIONS_YES_NO: RadioButtonOption[] = [
    { key: true, value: 'Oui' },
    { key: false, value: 'Non' },
  ];

  readonly habilitation = [
    { id: 'noH', value: '∅ = Document accessible par tous' },
    {
      id: EnumHabilitation.H1,
      value: EnumHabilitation.H1 + ' = Document accessible à Bpifrance, au Superviseur AAP, Evaluateurs, aux Opérateurs associés',
    },
    { id: EnumHabilitation.H2, value: EnumHabilitation.H2 + ' = Document accessible à Bpifrance, aux Opérateurs associés instructeur' },
    {
      id: EnumHabilitation.H3,
      value:
        EnumHabilitation.H3 + ' = Document accessible à à Bpifrance, au Superviseur AAP, Evaluateur ministère, aux Opérateurs associés',
    },
    {
      id: EnumHabilitation.H4,
      value:
        EnumHabilitation.H4 +
        ' = Document accessible à Bpifrance, au Superviseur AAP, Evaluateurs, Evaluateurs ministère, aux Opérateurs associés, Experts externes',
    },
  ];

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return !this.form.dirty;
  }

  isModification = false;

  get scope(): EnumScope {
    return this.form.get('scope').value;
  }

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public helperService: CreationDocumentHelperService,
    private referentielService: ReferentielHttpService,
    private showToastrService: ShowToastrService
  ) {
    super();
    this.idDocumentReferentiel = this.route.snapshot.params.id;
    this.isModification = this.idDocumentReferentiel !== undefined && this.idDocumentReferentiel !== null;
  }

  ngOnInit(): void {
    this.updateEtapeDataList();
    this.updateStructureDataList();
    this.updateTypePartenairesDataList();
    this.updateRoleStructuresDataList();
    this.form = this.helperService.createForm();
    if (this.isModification) {
      this.getReferentiel();
    } else {
      this.showForm = true;
    }
    this.form
      .get('scope')
      .valueChanges.pipe(this.takeUntilDestroyed())
      .subscribe(res => {
        if (this.structureSelectorComponent && this.roleStructuresComponent) {
          if (res === EnumScope.PROJET) {
            this.structureSelectorComponent.selectOptions.isDisabled = true;
            this.roleStructuresComponent.selectOptions.isDisabled = true;
            this.structureSelectorComponent.reset();
            this.roleStructuresComponent.reset();
            this.form.controls.typeStructures.clearValidators();
            this.form.controls.typeStructures.updateValueAndValidity();
            this.form.controls.roleStructures.clearValidators();
            this.form.controls.roleStructures.updateValueAndValidity();
          } else {
            this.structureSelectorComponent.reset();
            this.roleStructuresComponent.reset();
            this.structureSelectorComponent.selectOptions.isDisabled = false;
            this.roleStructuresComponent.selectOptions.isDisabled = false;
            this.form.controls.typeStructures.setValidators(Validators.required);
            this.form.controls.typeStructures.updateValueAndValidity();
            this.form.controls.roleStructures.setValidators(Validators.required);
            this.form.controls.roleStructures.updateValueAndValidity();
          }
        }
      });
  }

  private getReferentiel() {
    this.referentielService
      .getReferentielDocumentsAAP()
      .pipe(this.takeUntilDestroyed())
      .subscribe({
        next: (document: HttpResponse<ReferentielDocumentsAap[]>) => {
          document.body.forEach(f => {
            if (f.id === this.idDocumentReferentiel) {
              this.patchForm(f as any);
              this.revertStructureDataList();
              this.revertEtapeDataList();
              this.revertTypePartenairesDataList();
              this.revertRoleStructuresDataList();
              this.showForm = true;
            }
          });
        },
        error: (err: HttpErrorResponse) => {
          this.handleError(err);
        },
      });
  }

  handleError(err: HttpErrorResponse) {
    this.showToastrService.checkCodeError(err?.error);
  }

  updateTypePartenairesDataList(): void {
    this.typePartenairesDataList.push(
      {
        id: EnumTypePartenaire.INDIV,
        value: EnumTypePartenaire.toString(EnumTypePartenaire.INDIV),
        isSelected: false,
      },
      {
        id: EnumTypePartenaire.MULTI,
        value: EnumTypePartenaire.toString(EnumTypePartenaire.MULTI),
        isSelected: false,
      }
    );
  }

  revertTypePartenairesDataList() {
    const select: { id: any; value: string }[] = [];
    if (!this.form.value.typePartenaires.length) {
      return;
    }

    if (this.form.value.typePartenaires.includes(EnumTypePartenaire.INDIV)) {
      select.push({ id: EnumTypePartenaire.INDIV, value: EnumTypePartenaire.toString(EnumTypePartenaire.INDIV) });
    } else {
      select.push({ id: EnumTypePartenaire.MULTI, value: EnumTypePartenaire.toString(EnumTypePartenaire.MULTI) });
    }

    this.typePartenairesDataList.forEach((e: FilterDataInterface) => {
      const obj: string = e.id.toString();
      select.forEach((etape: { id: string; value: string }) => {
        if (obj === etape.id) {
          e.isSelected = true;
        }
      });
    });
  }

  updateRoleStructuresDataList(): void {
    this.roleStructuresDataList.push(
      {
        id: EnumRoleStructure.CANDIDAT,
        value: EnumRoleStructure.toString(EnumRoleStructure.CANDIDAT),
        isSelected: false,
      },
      {
        id: EnumRoleStructure.CHEF_DE_FILE,
        value: EnumRoleStructure.toString(EnumRoleStructure.CHEF_DE_FILE),
        isSelected: false,
      },
      {
        id: EnumRoleStructure.MANDATAIRE,
        value: EnumRoleStructure.toString(EnumRoleStructure.MANDATAIRE),
        isSelected: false,
      }
    );
  }

  revertRoleStructuresDataList() {
    const select: { id: any; value: string }[] = [];
    if (!this.form.value.roleStructures.length) {
      return;
    }

    if (this.form.value.roleStructures.includes(EnumRoleStructure.CANDIDAT)) {
      select.push({ id: EnumRoleStructure.CANDIDAT, value: EnumRoleStructure.toString(EnumRoleStructure.CANDIDAT) });
    } else if (this.form.value.roleStructures.includes(EnumRoleStructure.CHEF_DE_FILE)) {
      select.push({ id: EnumRoleStructure.CHEF_DE_FILE, value: EnumRoleStructure.toString(EnumRoleStructure.CHEF_DE_FILE) });
    } else {
      select.push({ id: EnumRoleStructure.MANDATAIRE, value: EnumRoleStructure.toString(EnumRoleStructure.MANDATAIRE) });
    }

    this.roleStructuresDataList.forEach((e: FilterDataInterface) => {
      const obj: string = e.id.toString();
      select.forEach((etape: { id: string; value: string }) => {
        if (obj === etape.id) {
          e.isSelected = true;
        }
      });
    });
  }

  updateEtapeDataList(): void {
    this.etapeDataList.push(
      {
        id: EnumProjetEtape.PRE_DEPOT,
        value: EnumProjetEtape.toString(EnumProjetEtape.PRE_DEPOT),
        isSelected: false,
      },
      {
        id: EnumProjetEtape.DEPOT,
        value: EnumProjetEtape.toString(EnumProjetEtape.DEPOT),
        isSelected: false,
      }
    );
  }

  revertEtapeDataList() {
    const select: { id: any; value: string }[] = [];
    if (!this.form.value.etapes) {
      return;
    }

    if (this.form.value.etapes.includes(EnumProjetEtape.PRE_DEPOT)) {
      select.push({ id: EnumProjetEtape.PRE_DEPOT, value: 'Pré-dépôt' });
    } else {
      select.push({ id: EnumProjetEtape.DEPOT, value: 'Dépôt' });
    }

    this.etapeDataList.forEach((e: FilterDataInterface) => {
      const obj: string = e.id.toString();
      select.forEach((etape: { id: string; value: string }) => {
        if (obj === etape.id) {
          e.isSelected = true;
        }
      });
    });
  }

  updateStructureDataList(): void {
    const typeStructure = EnumTypeStructure.forPersonneMorale().map(item => ({ label: item, value: EnumTypeStructure.toString(item) }));

    typeStructure.forEach((type: any) => {
      this.structureDataList.push({
        id: type.label,
        value: type.value,
        isSelected: false,
      });
    });
  }

  revertStructureDataList() {
    const select: { id: any; value: string }[] = [];
    const typeStructure = Object.entries(EnumTypeStructure as any).map(([label, value]) => ({ label, value }));

    if (!this.form.value.typeStructures) {
      return;
    }

    this.form.value.typeStructures.forEach((s: EnumTypeStructure) => {
      typeStructure.forEach((p: any) => {
        if (p.label === s) {
          select.push({ id: p.label, value: p.value });
        }
      });
    });

    this.structureDataList.forEach((s: FilterDataInterface) => {
      const obj: any = s.id;
      select.forEach((type: any) => {
        if (obj == type.id) {
          s.isSelected = true;
        }
      });
    });
  }

  goToDocuments(): void {
    this.helperService.goToDocuments(this.route, this.isModification);
  }

  checkEtapes() {
    let etapes: any = [];
    this.etapeSelectorComponent.getSelectedValues().forEach((etape: FilterDataInterface) => {
      if (etape.id === EnumProjetEtape.PRE_DEPOT) {
        etapes.push(
          EnumProjetEtape.PRE_DEPOT,
          EnumProjetEtape.DEPOT,
          EnumProjetEtape.INSTRUCTION,
          EnumProjetEtape.CONTRACTUALISATION,
          EnumProjetEtape.SUIVI
        );
      } else {
        etapes.push(EnumProjetEtape.DEPOT, EnumProjetEtape.INSTRUCTION, EnumProjetEtape.CONTRACTUALISATION, EnumProjetEtape.SUIVI);
      }
    });
    etapes = Array.from(new Set(etapes));
    this.form.patchValue({ etapes: etapes });
  }

  checkStructure() {
    const structures: any = [];
    this.structureSelectorComponent.getSelectedValues().forEach((structure: FilterDataInterface) => {
      structures.push(structure.id);
    });
    this.form.patchValue({ typeStructures: structures });
  }

  checkTypePartenaires() {
    const typePartenaires: any = [];
    this.structureProjetSelectorComponent.getSelectedValues().forEach((typePartenaire: FilterDataInterface) => {
      typePartenaires.push(typePartenaire.id);
    });
    this.form.patchValue({ typePartenaires: typePartenaires });
  }

  checkRoleStructures() {
    const roleStructures: any = [];
    this.roleStructuresComponent.getSelectedValues().forEach((roleStructure: FilterDataInterface) => {
      roleStructures.push(roleStructure.id);
    });
    this.form.patchValue({ roleStructures: roleStructures });
  }

  private patchForm(document: ReferentielDocumentsAapForm) {
    if (document.habilitationWKF1 === null) {
      this.form.controls.habilitationWKF1.setValue('noH');
    }
    if (document.habilitationWKF2 === null) {
      this.form.controls.habilitationWKF2.setValue('noH');
    }

    this.form.patchValue({ scope: document.scope.value, ...document.scope });
    this.form.controls.nom.disable({ onlySelf: true });
  }

  saveReferentiel(): void {
    this.submitClicked = true;
    this.helperService.saveReferentielFromForm(this.form, this.route, this.isModification, this.idDocumentReferentiel);
  }

  protected readonly EnumScope = EnumScope;
}
